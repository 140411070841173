import { useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

import { AMPLITUDE_KEY } from 'constants/data'

const AMHead = () => {
  useEffect(() => {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1, // 100% сессий для захвата
    })
    const options = { defaultTracking: true, includeUtm: true, saveEvents: true }
    amplitude.add(sessionReplayTracking)

    // Your existing initialization logic with Browser SDK
    amplitude.init(`${AMPLITUDE_KEY}`, options)
  }, [])

  return null
}

export { AMHead }
