import { FC } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import Button from '../../parts/button/button'
import PopupWrapperNew from 'containers/popup-wrapper-new/popup-wrapper-new'

import { buttonIdProfessionalAttention } from 'constants/clickableElementsId'

import styles from './popup-attention.module.scss'

interface IAttentionModal {
  close: () => void
}

const AttentionContent: FC<IAttentionModal> = ({ close }: IAttentionModal) => {
  const router = useRouter()
  const isForum = router.asPath.includes('technologii-dolgoletia')
  return (
    <PopupWrapperNew isBottom popupBlockMod={styles.popup}>
      <p className={styles.popupHeader}>Внимание</p>
      <div className={styles.content}>
        <p className={styles.firstParagraph}>
          Данный сайт содержит профессиональную специализированную информацию. Согласно действующему
          законодательству данные материалы могут быть доступны только медицинским работникам.
        </p>
        <p>
          Я подтверждаю, что являюсь специалистом с медицинским образованием и информация,
          полученная на сайте, будет использована мною только в профессиональной деятельности.
        </p>
      </div>
      <div
        className={clsx(styles.button, {
          [styles.forum]: isForum,
        })}
        onClick={close}
      >
        <Button id={buttonIdProfessionalAttention}>Подтверждаю</Button>
      </div>
    </PopupWrapperNew>
  )
}

export default AttentionContent
