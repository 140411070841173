const STUDENT_TAG = 'student'
const OLYMP_TAG = 'student2025'
const studentsRegText = 'Курс "Управление старением" проходит на&nbsp;платформе&nbsp;Medpoint'
const TELEGRAM_LINK = 'https://t.me/GeriatricsOlympiad'

const programData = [
  {
    date: '14 января',
    startDate: new Date(2024, 0, 14, 0, 0, 0, 0),
    finishDate: new Date(2024, 0, 15, 0, 0, 0, 0),
    txt: 'Начало регистрации команд',
  },
  {
    date: '28 февраля',
    startDate: new Date(2024, 1, 28, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 1, 0, 0, 0, 0),
    txt: 'Начало первого этапа, публикация задания',
  },
  {
    date: '<span>17</span> марта',
    startDate: new Date(2024, 2, 17, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 18, 0, 0, 0, 0),
    txt: '<span>Окончание первого этапа</span>, завершение приема решений',
  },
  {
    date: '24 марта',
    startDate: new Date(2024, 2, 24, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 25, 0, 0, 0, 0),
    txt: 'Результаты предыдущего этапа, начало второго этапа, публикация задания',
  },
  {
    date: '<span>30</span> марта',
    startDate: new Date(2024, 2, 30, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 31, 0, 0, 0, 0),
    txt: '<span>Окончание второго этапа</span>, завершение приема решений',
  },
  {
    date: '7 апреля',
    startDate: new Date(2024, 3, 7, 0, 0, 0, 0),
    finishDate: new Date(2024, 3, 8, 0, 0, 0, 0),
    txt: 'Результаты второго этапа',
  },
  {
    date: '14 мая',
    startDate: new Date(2024, 4, 14, 0, 0, 0, 0),
    finishDate: new Date(2024, 4, 15, 0, 0, 0, 0),
    txt: 'Очный тур и награждение победителей на Форуме "Технологии долготелия"',
  },
]

const webinarsForStudents = [1330, 1331, 1333, 1334, 1335, 1303]

export { STUDENT_TAG, OLYMP_TAG, TELEGRAM_LINK, studentsRegText, programData, webinarsForStudents }
