import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import AlertBase from 'components/alerts/alert-base/alert-base'
const AlertGuessCity = dynamic(() => import('components/alerts/alert-guess-city/alert-guess-city'))
const AlertPush = dynamic(() => import('components/alerts/alert-push/alert-push'))
import AlertAiBot from 'components/alerts/alert-ai-bot/alert-ai-bot'
import usePushToken from 'components/alerts/alert-push/usePushToken'
import useLocationCheck from './useLocationCheck'
const PortalWrap = dynamic(() => import('../portal-wrap/portal-wrap'), { ssr: false })
import useAlert from 'store/common/useAlert'
import useNavbarHeight from 'store/navbar/useNavbarHeight'

import { AlertType, IAlertMessage } from 'constants/alert'
import { Events } from 'constants/events'

import styles from './alert-wrapper.module.scss'

const usualMsgs = [
  AlertType.SAVE_SUCCESS,
  AlertType.SUB_SUCCESS,
  AlertType.UNSUB_SUCCESS,
  AlertType.THANKS,
  AlertType.EMAIL_CONFIRM,
  AlertType.COPY,
  AlertType.REGISTRATION_SUCCESS,
]
const usualErrorMsgs = [AlertType.SUB_ERROR, AlertType.SAVE_ERROR]

const getComponent = (message: IAlertMessage) => {
  if (usualMsgs.includes(message.type)) return <AlertBase message={message} />
  if (usualErrorMsgs.includes(message.type)) return <AlertBase message={message} isError />
  if (message.type === AlertType.GUESS_CITY) return <AlertGuessCity />
  if (message.type === AlertType.ALLOW_NOTIF) return <AlertPush />
  if (message.type === AlertType.AI_BOT_SUCCESS) return <AlertAiBot message={message} />
  if (message.type === AlertType.AI_BOT_UNSUCCESS) return <AlertAiBot message={message} isError />
  if (message.type === AlertType.CUSTOM_ALERT) return <div>{message.children}</div>
}

const AlertWrapper = () => {
  const [offsetRight, setOffsetRight] = useState(0)
  const { alerts } = useAlert()
  const { navbarHeight } = useNavbarHeight()
  usePushToken()
  useLocationCheck()

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      if (windowWidth >= 1500) {
        const right = (windowWidth - 1440) / 2
        setOffsetRight(right)
      } else setOffsetRight(0)
    }
    handleResize()
    window.addEventListener(Events.RESIZE, handleResize)
    return () => window.removeEventListener(Events.RESIZE, handleResize)
  }, [])

  return (
    <>
      <PortalWrap>
        <TransitionGroup
          className={styles.wrapper}
          style={
            offsetRight
              ? { right: `${offsetRight}px`, top: `${navbarHeight}px` }
              : { top: `${navbarHeight}px` }
          }
        >
          {alerts.map((el) => (
            <CSSTransition
              key={el.type + el.id}
              timeout={500}
              unmountOnExit
              classNames={{
                enter: styles.msgEnter,
                enterActive: styles.msgEnterActive,
                exit: styles.msgExit,
                exitActive: styles.msgExitActive,
              }}
            >
              {getComponent(el)}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </PortalWrap>
    </>
  )
}

export default AlertWrapper
