enum AlertType {
  SAVE_SUCCESS = 'SAVE_SUCCESS',
  SAVE_ERROR = 'SAVE_ERROR',
  SUB_SUCCESS = 'SUB_SUCCESS',
  UNSUB_SUCCESS = 'UNSUB_SUCCESS',
  SUB_ERROR = 'SUB_ERROR',
  EMAIL_CONFIRM = 'EMAIL_CONFIRM',
  GUESS_CITY = 'GUESS_CITY',
  ALLOW_NOTIF = 'ALLOW_NOTIF',
  THANKS = 'THANKS',
  COPY = 'COPY',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  AI_BOT_SUCCESS = 'AI_BOT_SUCCESS',
  AI_BOT_UNSUCCESS = 'AI_BOT_UNSUCCESS',
  CUSTOM_ALERT = 'CUSTOM_ALERT',
}

const AlertSubscriptionText = {
  [AlertType.SUB_SUCCESS]: 'Вы успешно записались на вебинар!',
  [AlertType.UNSUB_SUCCESS]: 'Вы отписались от вебинара',
  [AlertType.SUB_ERROR]: 'Произошла ошибка подписки',
}

const AlertErrorText = 'Произошла ошибка сохранения'

interface IAlertMessage {
  id: string
  text?: string
  email?: string
  title?: string
  children?: React.ReactNode
  type: AlertType
}

export { AlertType, AlertSubscriptionText, AlertErrorText }
export type { IAlertMessage }
